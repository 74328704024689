import React from 'react';
import TitleSection from '../components/home/TitleSection';
import MainLayout from '../components/MainLayout';
import Row from 'react-bootstrap/Row';
import '../css/ServicePage.css';
import '../css/MobileUnits.css';
import Col from 'react-bootstrap/Col';
import { Link } from 'gatsby';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Paragraph from '../components/common/Paragraph';
import MenuCountry from '../components/services/MenuCountry';
import Container from 'react-bootstrap/Container';
import Colors from '../utils/Colors';

/**
 * Mobile units template
 */
class MobileUnits extends React.Component {

	/**
	 * Constructor
	 * @param {*} props Component props
	 */
	constructor(props) {
		super(props);

		this.state = {
			// Selected category index
			categorySelectedIndex: 0,
			// is selected category is a list (of mobile units) (opposite to a page category)
			isListCategory: this.isCategoryList(0),
			// selected category index
			countrySelectedIndex: -1
		};

		this.mainLayout = React.createRef();

		this.selectCategory = this.selectCategory.bind(this);

		this.selectCountry = this.selectCountry.bind(this);

		this.openCountryMenu = this.openCountryMenu.bind(this);

		this.onSlideCategoryChanged = this.onSlideCategoryChanged.bind(this);

		this.openVideo = this.openVideo.bind(this);
	}

	/**
	 * Select category
	 * @param {int} index Category index to select
	 * @param {boolean} isListCategory is selected category is a list (of mobile units) (opposite to a page category)
	 */
	selectCategory(index, isListCategory) {
		this.setState({
			categorySelectedIndex: index,
			isListCategory: isListCategory,
			countrySelectedIndex: -1
		});
	}

	/**
	 * Select a country
	 * @param {int} index Country index to select
	 */
	selectCountry(index) {
		this.setState({
			countrySelectedIndex: index
		});
	}

	/**
     * Check if a text is empty (empty paragraph for example). Necessary beacause Kontent return "<p><br></p>" when rich text is not filled.
     * @param {string} text Text to check
     */
	isTextEmpty(text) {
		return !(text && text !== '<p><br></p>');
	}

	/**
	 * Open fullscreen country menu (small version)
	 */
	openCountryMenu() {
		document.getElementById('emg-menu-country').className = '';
		document.body.classList.add('menu-open');
	}

	/**
	 * On category changed by slide
	 * @param {SlideEvent} event On category changed by slide
	 */
	onSlideCategoryChanged(event) {
		this.selectCategory(event.item, this.isCategoryList(event.item));
	}

	/**
	 * is category is a list (of mobile units) (opposite to a page category)
	 * @param {int} index Category index
	 */
	isCategoryList(index) {
		let category = this.props.pageContext.data.elements.categories.value[index];
		console.log(category.system.type);
		return category.system.type === 'mobileunitcategory';
	}

	/**
	 * Open video popover
	 * @param {string} url Video url
	 * @param {string} type Video type
	 */
	openVideo(url, type) {
		this.mainLayout.current.openVideo(url, type);
	}

	render() {
		let data = this.props.pageContext.data.elements;

		let color = '';
		let menuIndex = 0;

		if (this.props.pageContext.index === 1) {
			color = 'emg-pink';
			menuIndex = 1;
		}
		else {
			color = 'emg-yellow';
			menuIndex = 2;
		}

		let countries = '';
		let countriesSmall = '';

		let countryMenu = '';

		if (this.state.isListCategory) {
			countries = (
				/* Countries list (big version) */
				<Row className="mobileunits-countries-big mobileunits-countries-row servicepage-section ">
					<Col xs={{ span: 10, offset: 1 }} className="mobileunits-countries-container">
						<div
							onClick={(e) => this.selectCountry(-1)}
							className={
								this.state.countrySelectedIndex === -1 ? (
									'mobileunits-country-selected mobileunits-country-name'
								) : (
									'mobileunits-country-name'
								)
							}>
							ALL
							<div className="mobileuntis-country-selected-line" />
						</div>
						{data.categories.value[this.state.categorySelectedIndex].elements.countries.value.map((country, index) => (
							<div
								onClick={(e) => this.selectCountry(index)}
								className={
									this.state.countrySelectedIndex === index ? (
										'mobileunits-country-selected mobileunits-country-name'
									) : (
										'mobileunits-country-name'
									)
								}
								key={'mobileunits-country-' + index}>
								{country.elements.name.value}
								<div className="mobileuntis-country-selected-line" />
							</div>
						))}
					</Col>
				</Row>
			);

			let countryName = '';

			if (this.state.countrySelectedIndex === -1) {
				countryName = 'ALL COUNTRIES';
			}
			else {
				countryName =
					data.categories.value[this.state.categorySelectedIndex].elements.countries.value[this.state.countrySelectedIndex].elements.name
						.value;
			}

			let countryList = [];

			for (let i = 0; i < data.categories.value[this.state.categorySelectedIndex].elements.countries.value.length; i++) {
				countryList.push(data.categories.value[this.state.categorySelectedIndex].elements.countries.value[i].elements.name.value);
			}
			countriesSmall = (
				<Row className="mobileunits-countries-small mobileunits-countries-row servicepage-section">
					<Col xs={{ span: 10, offset: 1 }} className="mobileunits-countries-container">
						<div className="mobileunits-country-selected mobileunits-country-name" onClick={this.openCountryMenu}>
							{countryName} <div className="emg-arrow-bottom" />
							<div className="mobileuntis-country-selected-line" />
						</div>
					</Col>
				</Row>
			);

			countryMenu = <MenuCountry activeItem={this.state.countrySelectedIndex} menuItems={countryList} onSelect={this.selectCountry} />;
		}

		// prepare category details

		let unitsList = '';
		let categoryPage = '';

		// if category is a list category, add country selection and the list
		if (this.state.isListCategory) {
			let units = [];

			if (this.state.countrySelectedIndex !== -1) {
				units =
					data.categories.value[this.state.categorySelectedIndex].elements.countries.value[this.state.countrySelectedIndex].elements
						.mobileunits.value;
			}
			else {
				for (let i = 0; i < data.categories.value[this.state.categorySelectedIndex].elements.countries.value.length; i++) {
					let country = data.categories.value[this.state.categorySelectedIndex].elements.countries.value[i];

					for (let j = 0; j < country.elements.mobileunits.value.length; j++) {
						units.push(country.elements.mobileunits.value[j]);
					}
				}
			}

			unitsList = (
				<Row className="mobileunits-countries-row servicepage-section">
					<Col xs={{ span: 10, offset: 1 }} className="mobileunits-units-container">
						{units.map((unit, index) => {

							let link = "";

							if (unit.elements.pdf.value[0]){
								link = unit.elements.pdf.value[0].url;
							}
							else if (unit.elements.url.value){
								link = unit.elements.url.value;
							}

							return 	<div className="mobileunits-unit-line-container" key={'mobileunits-unit-' + index}>
										<div className="mobileunits-unit-line-date-container">
											<div className="mobileunits-unit-line-title">{unit.elements.name.value}</div>
											<div className="">{unit.elements.description.value}</div>
										</div>

										<a href={link} target="_blank" rel="noreferrer">
											{' '}
											<div className="mobileunits-unit-line-download" />
										</a>
									</div>;
						})}
					</Col>
				</Row>
			);
		}
		// Category is a page category : prepare the page
		else {
			let category = data.categories.value[this.state.categorySelectedIndex].elements;
			let mainBloc = '';

			if (!this.isTextEmpty(category.title.value) || !this.isTextEmpty(category.title.value)) {
				let titleBloc = '';
				if (!this.isTextEmpty(category.title.value)) {
					titleBloc = <div className="servicepage-content-title emg-title">{category.title.value}</div>;
				}

				let textBloc = '';
				if (!this.isTextEmpty(category.text.value)) {
					textBloc = <div className="emg-text" dangerouslySetInnerHTML={{ __html: category.text.value }} />;
				}

				mainBloc = (
					<Row className="mobileunits-category-page-main">
						<Col xs={{ span: 10, offset: 1 }}>
							{titleBloc}
							{textBloc}
						</Col>
					</Row>
				);
			}

			let paragraphs = '';

			if (category.paragraphs.value.length > 0) {
				paragraphs = category.paragraphs.value.map((paragraph, index) => (
					<Paragraph index={index} data={paragraph} color={color} openVideo={this.openVideo} key={'mobileunits-paragraphs-' + index} />
				));
			}

			categoryPage = (
				<Row className="mobileunits-category-page-container">
					<Container fluid>
						{mainBloc}
						{paragraphs}
					</Container>
				</Row>
			);
		}

		// Nb of categories displayed in carousel in small version 
		let responsive = {
			0: {
				items: 1
			},
			590: {
				items: 2
			},
			992: {
				items: 3
			}
		};

		return (
			<MainLayout scrollSnap={false} activeItem={menuIndex} className="mobileunits-container" ref={this.mainLayout}>
				{/* COUNTRY MENU*/}
				{countryMenu}

				{/* TITLE SECTION*/}
				<Row className="nearly-fullheight fullheight">
					<TitleSection
						background={data.background.value[0].url}
						title={data.title.value}
						lineColor={color}
						text={data.subtitle.value}
					/>
				</Row>
				{/* BACK BTN*/}
				<Row className="servicepage-section">
					<Col xs={{ span: 10, offset: 1 }}>
						<Link to={this.props.pageContext.parentUrl + '#' + this.props.pageContext.pagePath} className="servicepage-back">
							<div className="emg-arrow-left" /> BACK
						</Link>
					</Col>
				</Row>

				{/* CATEGORIES SECTION BIG VERSION */}
				<Row className="hidden-small-version servicepage-section">
					<Col xs={{ span: 10, offset: 1 }} className="mobileunits-categories-container">
						{data.categories.value.map((category, index) => (
							<div
								onClick={(e) => this.selectCategory(index, category.system.type === 'mobileunitcategory')}
								className={
									this.state.categorySelectedIndex === index ? (
										'mobileunits-category-selected mobileunits-category-name'
									) : (
										'mobileunits-category-name'
									)
								}
								key={'mobileunits-category-big-' + index}>
								{category.elements.name.value}
								<div style={{backgroundColor:Colors.getColorHexaByName(color)}} className="mobileuntis-category-selected-line" />
							</div>
						))}
					</Col>
				</Row>

				{/* CATEGORIES SECTION small version */}
				<Row className="masked-big-version servicepage-section">
					<Col xs={{ span: 11, offset: 1 }} className="mobileunits-categories-container">
						<AliceCarousel
							className="hidden-big-version"
							autoHeight={false}
							startIndex={this.state.categorySelectedIndex}
							infinite={true}
							duration={500}
							dotsDisabled={true}
							buttonsDisabled={true}
							responsive={responsive}
							onSlideChanged={this.onSlideCategoryChanged}>
							{data.categories.value.map((category, index) => (
								<div
									onClick={(e) => this.selectCategory(index, category.system.type === 'mobileunitcategory')}
									className={
										this.state.categorySelectedIndex === index ? (
											'mobileunits-category-selected mobileunits-category-name'
										) : (
											'mobileunits-category-name'
										)
									}
									key={'mobileunits-category-small-' + index}>
									<div className="mobileunits-category-small-container">
										{category.elements.name.value}
										<div className="mobileuntis-category-selected-line" />
									</div>
								</div>
							))}
						</AliceCarousel>
						<div className="mobileunits-categories-small-mask" />
					</Col>
				</Row>
				
				{/* COUNTRIES SECTION BIG VERSION */}
				{countries}
				{/* Countries SECTION small VERSION */}
				{countriesSmall}

				{/* if category is a list category : list of mobile units */}
				{unitsList}

				{/* if category is a page category : page content */}
				{categoryPage}

				<Row className="servicepage-section" />
			</MainLayout>
		);
	}
}

export default MobileUnits;
