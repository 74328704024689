import React from 'react';
import { Link } from 'gatsby';
import '../../css/menu/MenuBurger.css';
import { StaticQuery, graphql } from 'gatsby';

/**
 * Fullscreen menu for Country selection in mobileunits page (small version)
 */
class MenuCountry extends React.Component {

	/**
	 * Close country menu
	 */
	closeBurgerMenu() {
		document.getElementById('emg-menu-country').className = 'collapse';
		document.body.classList.remove('menu-open');
	}

	/**
	 * Select a country
	 * @param {int} index Selected item index
	 */
	selectCountry(index) {

		// Call callback
		this.props.onSelect(index);

		// close menu
		this.closeBurgerMenu();

	}

	render() {
		return (
			<StaticQuery
				query={menuCountryQuery}
				render={(data) => {
					let allCountriesActiveClass = '';
					if (this.props.activeItem === -1) {
						allCountriesActiveClass = 'active ';
					}

					return (
						<div id="emg-menu-country" className="collapse">
							<div className="burger-menu-black-filter" />
							{/* CLOSE */}
							<div className="burger-menu-close" onClick={this.closeBurgerMenu}>
								<svg style={{ width: '100%', height: '100%' }} viewBox="0 0 30 30" preserveAspectRatio="none">
									<line x1="0" y1="0" x2="30" y2="30" className="burger-menu-close-line" />
									<line x1="0" y1="30" x2="30" y2="0" className="burger-menu-close-line" />
								</svg>
							</div>
							<div className="burger-menu-content">
								{/* LOGO */}
								<div className="burger-menu-header">
									<div className="menu-burger-logo">
										<Link to="/home" onClick={this.closeBurgerMenu}>
											<img src={data.allKontentItemMenu.edges[0].node.elements.logo.value[0].url} width="136" alt="EMG Logo" />
										</Link>
									</div>
								</div>
								{/* COUNTRIES */}
								<div className="burger-menu-links-container">
									<div className="burger-menu-mainlinks-container">
										<div className={allCountriesActiveClass + ' burger-menu-link'} onClick={() => this.selectCountry(-1)}>
											ALL COUNTRIES
										</div>
										{this.props.menuItems.map((item, index) => {
											let activeClass = '';
											if (this.props.activeItem || this.props.activeItem === 0) {
												if (this.props.activeItem === index) {
													activeClass = 'active ';
												}
											}
											return (
												<div
													key={'vertical_menu_' + index}
													className={activeClass + ' burger-menu-link'}
													onClick={() => this.selectCountry(index)}>
													{item}
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					);
				}}
			/>
		);
	}
}

export default MenuCountry;

// Graphql request to get logo url
export const menuCountryQuery = graphql`
	query MenuCountry {
		allKontentItemMenu {
			edges {
				node {
					elements {
						logo {
							value {
								url
							}
						}
					}
				}
			}
		}
	}
`;
