import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../css/common/Paragraph.css';

/**
 * Paragraph (Service Paragraph in Kentico) component (with downloads, videos, side text, etc)
 */
class Paragraph extends React.Component {
    
	/**
     * Check if a text is empty (empty paragraph for example). Necessary beacause Kontent return "<p><br></p>" when rich text is not filled.
     * @param {string} text Text to check
     */
	isTextEmpty(text) {
		return !(text && text !== '<p><br></p>');
	}

	render() {
		let paragraph = this.props.data;
		let index = this.props.index;
		let color = this.props.color;

		//console.log(paragraph.elements.side_title);

		let hasDownloads = paragraph.elements.downloads.value.length > 0;
		let hasSideText = paragraph.elements.side_title && !this.isTextEmpty(paragraph.elements.side_title.value);
		let hasRightCol = hasDownloads || hasSideText;
		let firstColSpan = hasRightCol ? 7 : 10;

		let downloadCol = '';

		//console.log(paragraph.elements);

		if (hasDownloads) {
			downloadCol = (
				<Col xl={{ span: 3 }} xs={{ span: 10, offset: 1 }} className="mt-3" key="emg-paragraph-dowload">
					<div className="emg-title">DOWNLOADS</div>
					<div className="paragraph-downloads emg-text">
						<ul>
							{paragraph.elements.downloads.value.map((download, index) => (
								<li key={'download-' + index}>
									<a href={download.url} target="_blank" rel="noreferrer">
										{download.name}
									</a>
								</li>
							))}
						</ul>
					</div>
				</Col>
			);
		}

		let sideCol = '';

		if (hasSideText) {
			sideCol = (
				<Col xl={{ span: 2, offset:1 }} xs={{ span: 10, offset: 1 }} className="mt-3" key="emg-paragraph-side">
					<div className="emg-title">{paragraph.elements.side_title.value}</div>
					<div className="paragraph-downloads emg-text" dangerouslySetInnerHTML={{ __html: paragraph.elements.side_text.value }} />
				</Col>
			);
		}

		let videoCol = '';

		if (paragraph.elements.video.value.length > 0) {
			videoCol = (
				<Col xs={{ span: 10, offset: 1 }} xl={{ span: firstColSpan, offset: 1 }} className="mt-4" key="emg-paragraph-video">
					<button
						className={color + '-background emg-button'}
						onClick={(e) => this.props.openVideo(paragraph.elements.video.value[0].url, paragraph.elements.video.value[0].type)}>
						<div className="emg-button-mask" />
						WATCH VIDEO
					</button>
				</Col>
			);
		}

		let mainBloc = '';
		let result = '';

		if (!this.isTextEmpty(paragraph.elements.title.value) || !this.isTextEmpty(paragraph.elements.text.value)) {
			let titleBloc = '';
			if (!this.isTextEmpty(paragraph.elements.title.value)) {
				titleBloc = <div className="emg-title">{paragraph.elements.title.value}</div>;
			}

			let textBloc = '';
			if (!this.isTextEmpty(paragraph.elements.text.value)) {
				textBloc = <div className="emg-text" dangerouslySetInnerHTML={{ __html: paragraph.elements.text.value }} />;
			}

			mainBloc = (
				<Col xs={{ span: 10, offset: 1 }} xl={{ span: firstColSpan, offset: 1 }} key="emg-paragraph-mainbloc">
					{titleBloc}
					{textBloc}
				</Col>
			);

			result = [ mainBloc, downloadCol, sideCol, videoCol ];
		}
		else {
			result = [ mainBloc, sideCol, videoCol, downloadCol ];
		}

		return (
			<Row className="servicepage-section servicepage-paragraph" key={'paragraph-' + index}>
				{result}
			</Row>
		);
	}
}

export default Paragraph;
