import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../css/home/TitleSection.css';
import Colors from '../../utils/Colors';

/**
 * TitleSection (background, title, subtitle) used in homepage and many others
 */
class TitleSection extends React.Component {
	
	render() {
		let customBG = {
			backgroundImage: 'url(' + this.props.background + ')'
		};

		let customLineColor = {};
		if (this.props.lineColor) {
			customLineColor = { border: 'solid 2px ' + Colors.getColorHexaByName(this.props.lineColor) };
		}

		let fullwidth = false;
		if (this.props.fullwidth) {
			fullwidth = this.props.fullwidth;
		}

		let indexBloc = '';
		if (this.props.index) {
			let indexStr = this.props.index;

			if (this.props.index < 10) indexStr = '0' + this.props.index;

			indexBloc = <div className="titleSection-index section-index">{indexStr}</div>;
		}

		let textBloc = '';

		if (this.props.text) {
			textBloc = <div className={this.props.fullwidth ? "title-subtitle emg-text" : "titleSection-subtitle title-subtitle emg-text"}>{this.props.text}</div>;
		}

		return (
			<div className="titleSection section d-flex align-items-end " style={customBG}>
				<div className="blackfilter" />

				{indexBloc}

				<div className="lighttriangle">
					<svg style={{ width: '100%', height: '100%' }} viewBox="0 0 100 100" preserveAspectRatio="none">
						<polygon points="0,0 100,100 0,100" fill="white" />
					</svg>
				</div>

				<div className="lighttriangle2">
					<svg style={{ width: '100%', height: '100%' }} viewBox="0 0 100 100" preserveAspectRatio="none">
						<polygon points="0,0 100,0 100,100" fill="white" />
					</svg>
				</div>
				<Container fluid className={"no-gutters " + (this.props.fullwidth ? "py-5" : "")}>
					<Row>
						<Col
							xl={{ span: this.props.fullwidth ? 10 : 6, offset: 1 }}
							lg={{ span: this.props.fullwidth ? 10 : 7, offset: 1 }}
							md={{ span: this.props.fullwidth ? 10 : 8, offset: 1 }}
							xs={{ span: this.props.fullwidth ? 10 : 8, offset: 1 }}
							className="titleSection-title">
							{this.props.title}

							<div className="titleline" style={customLineColor} />

							{textBloc}
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default TitleSection;
